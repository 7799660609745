// navigation_init.js
exports.navbar = [
  {
    id: 'demo',
    type: 'button',
    text: 'Demo',
    color: 'is-primary',
    icon: 'demo-icon',
    name: 'Demo',
    url: 'https://app.shir.ly/captain_january',
  },
  {
    id: 'login',
    type: 'button',
    text: 'Login',
    color: 'is-primary',
    icon: 'login-icon',
    name: 'Login',
    route: '/login',
  },
  {
    id: 'admin',
    type: 'button',
    text: 'Admin',
    color: 'is-danger',
    icon: 'admin-icon',
    name: 'Admin',
    route: '/admin',
  },
  {
    id: 'account',
    type: 'profile',
    text: 'Account',
    color: 'is-primary',
    icon: 'account-icon',
    name: 'Account',
    route: '/account',
  },
];


exports.account = [
  {
    id: 'projects',
    text: 'Projects',
    color: 'is-primary',
    name: 'Projects',
    route: 'https://app.shir.ly/projects',
  },
  {
    id: 'new_project',
    text: 'New Project',
    color: 'is-primary',
    route: '/new-project',
    name: 'New Project',
  },
  {
    id: 'profile',
    text: 'Profile',
    color: 'is-primary',
    route: 'https://app.shir.ly/profile',
    name: 'Profile',
  },
];