export default [{
  path: '/home',
  name: 'Home',
  alias: '/',
  component: () => import( /* webpackChunkName: "about" */ '@/views/Home.vue'),
  meta: {
    requiresAuth: false
  }
},
{
  path: '/login',
  name: 'Login',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import( /* webpackChunkName: "about" */ '@/views/Login.vue')
},
{
  path: '/signup',
  name: 'Sign Up',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import( /* webpackChunkName: "about" */ '@/views/SignUp.vue')
},
{
  path: '/account',
  name: 'Account',
  component: () => import( /* webpackChunkName: "profile" */ '@/views/Account.vue'),
  meta: {
    requiresAuth: true
  },
  props: true,
},
{
  path: '/admin',
  name: 'Admin',
  component: () => import( /* webpackChunkName: "profile" */ '@/views/Admin.vue'),
  meta: {
    requiresAuth: true,
    requiresPermission: true
  },
  props: true,
},
{
  path: '/new-project',
  component: () => import( /* webpackChunkName: "directory" */ '@/views/NewProject.vue'),
  meta: {
    requiresAuth: true,
  },
  props: true,
  children: [
    {
      path: '/',
      name: 'NewProject.index',
      component: () => import( /* webpackChunkName: "directory" */ '@/components/new-project/NewProjectSteps.vue'),
      meta: {
        requiresAuth: true
      },
      props: {
        data: 'steps'
      },
    },
    {
      path: 'success',
      name: 'NewProject.success',
      component: () => import( /* webpackChunkName: "directory" */ '@/components/PaymentSuccess.vue'),
      meta: {
        requiresAuth: true
      },
      props: route => ({ session_id: route.query.session_id })
    },
    {
      path: 'failed',
      name: 'NewProject.failed',
      component: () => import( /* webpackChunkName: "directory" */ '@/components/PaymentFailed.vue'),
      meta: {
        requiresAuth: true
      },
    }]
},
]