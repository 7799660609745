<template>
<div>
  <b-modal id="modal" v-model="isLoadingModalActive" full-screen has-modal-card close-button-aria-label>
    <template>
        <section class="section">
          <b-loading :is-full-page="isFullPage" v-model="isAppLoading" :can-cancel="canCancelAppLoading" />
        </section>
    </template>
  </b-modal>
</div>
</template>

<script>
export default {
  name: 'AppLoadingModal',
  props: {

  },
  components: {

  },
  data() {
    return {
      isAppLoading: true,
      isFullPage: true,
      canCancelAppLoading: false
    }
  },
  mounted(){
    // console.log('loading modal mounted')
    // console.log(this.isLoadingModalActive)
  },
  destroyed(){
    // console.log('loading modal destroyed')
  },
  computed: {
    isLoadingModalActive:{
      get: function() {
        return this.$store.getters['Admin/getIsLoadingModalActive']
      },
      // setter
      set: function(value) {
        this.$store.dispatch('Admin/setIsLoadingModalActive', value)
      }
    }
  },
}
</script>

<style scoped>

</style>
