<template>
  <section>
    <figure v-if="image.url">
      <cld-context cloudName="cloudName" :width="image.width">
        <cld-image :publicId="image.url" />
      </cld-context>
    </figure>
    <figure v-else>
      <inline-svg :height="svg.height" :width="svg.width" :src="require(`@/bulma-plain-assets/logos/${svg.path}`)" />
    </figure>
  </section>
</template>

<script>
import {
  CldContext,
  CldImage
} from 'cloudinary-vue'
import InlineSvg from 'vue-inline-svg';
export default {
  name: 'AppLogo',
  props: {
    svg: {
      type: Object,
      default() {
        return {
          path: 'plainb-logo.svg',
          height: '0px',
          width: '96px'
        }
      }
    },
    image: {
      type: Object,
      default() {
        return {
          width: '96px',
        }
      }
    }
  },
  components: {
    CldContext,
    CldImage,
    InlineSvg
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>
/* if you set up bulma-css-vars, this will be generated in your bulma scss */
.customSvg {
  fill: var(--primary-invert);
}
</style>
