/* stripe.store.js */
import {
  firestoreAction
} from 'vuexfire'
import {
  DB
} from '@/firebase/db'
import {
  Auth
} from '@/firebase/auth'

import getFirestoreRef from '@/store/modules/plugins/getFirestoreRef';

const initialState = () => ({
  project_sessions: null,
  current_project_session: {},
  newly_created_project: null,
  project_id_loading_message: null,
  project_session_navigation_button_disabled: {
    previous: false,
    next: true
  }
})
// State object
const state = initialState()
// Getter functions
const getters = {
  getState: (state) => (key) => {
    return state[key]
  },
  getStatePromise: (state) => (key) => {
    return new Promise((resolve) => {
      resolve(state[key])
    })
  },
  getProjectSessions: state => state.project_sessions,
  getCurrentProjectSession: state => state.current_project_session,
  getCurrentProjectSessionValueByKey: (state) => (project_key) => {
    const delimiter = 'current_project_session_'
    let key = delimiter + project_key;
    return state[key];
  },
}
// Actions
const actions = {
  reset({
    commit
  }) {
    commit('RESET')
  },
  setState: (context, payload) => {
    return new Promise((resolve) => {
      context.commit('SET_STATE', payload);
      resolve('okay');
    })
  },
  bindState: firestoreAction(({ bindFirestoreRef }, payload) => {
    const { key, path } = payload;
    return bindFirestoreRef(key, getFirestoreRef(DB, path));
  }),
  bindProjectSessions: firestoreAction(async (context) => {
    return await new Promise((resolve, reject) => {
      context.bindFirestoreRef('project_sessions', DB.collection('users').doc(Auth.currentUser.uid).collection('project_sessions').orderBy("createdAt", "asc"))
        .then(() => {
          resolve(context.state.project_sessions[0]);
        })
        .catch((err) => {
          reject(err)
        })
    })
  }),
  bindNewlyCreatedProject: firestoreAction((context, project_id) => {
    let pid = project_id ? project_id : context.state.current_project_session_project_id;
    context.bindFirestoreRef('newly_created_project', DB.collection('projects').doc(pid));
  }),
}
// Mutations
const mutations = {
  RESET: state => {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_STATE: (state, payload) => {
    const keys = payload.key.split(".");
    const value = payload.value;

    let current = state;
    for (let i = 0; i < keys.length; i++) {
      // If it's the last key, set the value
      if (i === keys.length - 1) {
        current[keys[i]] = value;
      } else {
        // If the key doesn't exist in the state, create it as an empty object
        if (!current[keys[i]]) {
          current[keys[i]] = {};
        }

        // Move our current position in the state object down one level
        current = current[keys[i]];
      }
    }
  },
  SET_CURRENT_PROJECT_SESSION_KEY: (state, payload) => {
    state[payload.key] = payload.value;
  },
  SET_CURRENT_PROJECT_SESSION: (state, payload) => {
    state.current_project_session = payload;
  },
  SET_SESSION_ID: (state, payload) => {
    state.session_id = payload
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
