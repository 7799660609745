<template>
  <div v-if="type === 'default'">
    <b-navbar-item :tag="route ? 'router-link' : 'a'" :to="{ name: route }" :href="url ? url : null"
      :target="url ? 'demo' : null">
      <div class="buttons">
        <a class="button" :class="buttonClass">
          <i v-if="icon" :class="icon"></i> <!-- Add this line to include icons -->
          {{ text }}
        </a>
      </div>
    </b-navbar-item>
  </div>
</template>  

<script>
export default {
  name: 'AppNavbarButton',
  props: {
    route: {
      type: String,
      default: () => ''
    },
    url: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: () => ''
    },
    type: {
      type: String,
      default: 'default'
    },
    buttonClass: {
      type: String,
      default: 'is-primary'
    },
    icon: { // Add this prop for icons
      type: String,
      default: null
    }
  },
  data() {
    return {}
  }
}
</script>
