/* user.store.js */

const initialState = () => ({
  is_app_bound: false,
  is_app_loading: true,
  adminBreadcrumb: [],
  orientation: '',
  device: '',
  activeStep: 0,
  isAppLoading: false,
  isLoadingModalActive: false,
  isPreviousDisabled: false,
  isNextDisabled: false,
  search: '',
  componentKey: 0,
  bulkImportData: [{
    "first_name": "Jane",
    "last_name": "Doe",
    "email": "jdoe@example.com",
    "phone": "+1 404 555 5555"
  }],
  bulkImportErrors: [],
  bulkImportOptions: ['profile_images'],
  baseSteps: [{
    id: 'info',
    name: 'Info',
    displayed: true
  },
  {
    id: 'type',
    name: 'Type',
    displayed: true
  },
  {
    id: 'package',
    name: 'Package',
    displayed: true,
  },
  {
    id: 'checkout',
    name: 'Checkout',
    displayed: true,
  }]
})
// State object
const state = initialState()
// Getter functions
const getters = {
  getState: (state) => (key) => {
    return state[key]
  },
  getStatePromise: (state) => (key) => {
    return new Promise((resolve) => {
      resolve(state[key])
    })
  },
  getActiveMenuItem: state => state.activeMenuItem,
  getAdminBreadcrumb: state => state.adminBreadcrumb,
  getOrientation: state => state.orientation,
  getDevice: state => state.device,
  getIsAppLoading: state => state.isAppLoading,
  getIsLoadingModalActive: state => state.isLoadingModalActive,
  getActiveStep: state => state.activeStep,
  getIsPreviousDisabled: state => state.isPreviousDisabled,
  getIsNextDisabled: state => state.isNextDisabled,
  getSearch: state => state.search,
  getBaseSteps: state => state.baseSteps,
  getComponentKey: state => state.componentKey,
  getBulkImportData: state => state.bulkImportData,
  getBulkImportErrors: state => state.bulkImportErrors,
  getBulkImportOptions: state => state.bulkImportOptions,
  getBaseStepIndexById: (state) => (id) => {
    return state.baseSteps.findIndex((x) => x.id === id);
  },
}
// Actions
const actions = {
  reset({ commit }) {
    commit('RESET')
  },
  setState: (context, payload) => {
    return new Promise((resolve) => {
      context.commit('SET_STATE', payload);
      resolve('Okay');
    })
  },
  bindAppRef: async (context) => {
    return new Promise((resolve) => {
      try {
        const user_id = context.rootGetters['User/getState']('user')?.id
        if (user_id) {
          context.dispatch('Project/bindProjectSessions', {}, { root: true }).then((current_project_session) => {
            context.dispatch('Project/bindState', { key: 'current_project_session', path: `users/${user_id}/project_sessions/${current_project_session?.id}` }, { root: true }).then(res => {
              resolve({
                type: 'Success',
                message: 'Project sessions bound.'
              })
            })
          }).catch((e) => {
            resolve({
              type: 'Failed',
              message: 'Could not bind project sessions (likely no project sessions in user document.',
              data: e
            })
          })
        }
      } catch (e) {
        resolve({
          type: 'Failed',
          message: 'Could not bind project sessions (likely no project sessions in user document.)'
        })
      }
    })
  },
  forceRerender: (context) => {
    context.dispatch('setComponentKey', context.state.componentKey++)
  },
  setActiveMenuItem: (context, payload) => {
    context.commit('SET_ACTIVE_MENU_ITEM', payload)
  },
  setAdminBreadcrumb: (context, payload) => {
    context.commit('SET_ADMIN_BREADCRUMB', payload)
  },
  handleOrientationChange: (context, payload) => {
    context.commit('SET_ORIENTATION', payload.orientation)
  },
  handleMediaBreakpointChange: (context, payload) => {
    context.commit('SET_MEDIA_BREAKPOINT', payload.breakpoint)
  },
  setActiveStep: (context, payload) => {
    context.commit('SET_ACTIVE_STEP', payload)
  },
  setIsPreviousDisabled: (context, payload) => {
    context.commit('SET_IS_PREVIOUS_DISABLED', payload)
  },
  setIsNextDisabled: (context, payload) => {
    context.commit('SET_IS_NEXT_DISABLED', payload)
  },
  setSearch: (context, payload) => {
    context.commit('SET_SEARCH', payload)
  },
  setComponentKey: (context, payload) => {
    context.commit('SET_COMPONENT_KEY', payload)
  },
  setBulkImportData: (context, payload) => {
    context.commit('SET_BULK_IMPORT_DATA', payload)
  },
  setBulkImportErrors: (context, payload) => {
    context.commit('SET_BULK_IMPORT_ERRORS', payload)
  },
  setBulkImportOptions: (context, payload) => {
    context.commit('SET_BULK_IMPORT_OPTIONS', payload)
  },
  setIsAppLoading: (context, payload) => {
    context.commit('SET_IS_APP_LOADING', payload)
  },
  setIsLoadingModalActive: (context, payload) => {
    context.commit('SET_IS_LOADING_MODAL_ACTIVE', payload)
  },
  navigationButtonDiscernment: async (context, payload) => {
    const step = payload.step;
    const step_previous = await context.dispatch('isStepNavigationButtonClickable', { step: step, navigation_button: 'previous' });
    const step_next = await context.dispatch('isStepNavigationButtonClickable', { step: step, navigation_button: 'next' });
    context.dispatch('setIsPreviousDisabled', step_previous);
    context.dispatch('setIsNextDisabled', step_next);
  },
  isStepNavigationButtonClickable: async (context, payload) => {
    const step = payload.step;
    const navigation_button = payload.navigation_button;
    let info_array = ['project_id', 'project_title', 'project_description'];
    let info_boolean_array = [];
    let type_array = ['project_type', 'project_type_other'];
    let type_boolean_array = [];
    let package_array = ['project_package'];
    let package_boolean_array = [];
    switch (step) {
      case 'info':
        info_array.map(async (item) => {
          const key = item;
          const value = context.rootGetters['Project/getCurrentProjectSessionValueByKey'](key);
          info_boolean_array.push(context.dispatch('isValueSufficient', { key: key, value: value })); // not null, empty, or blank
        })
        switch (navigation_button) {
          case 'previous':
            return true;
          case 'next':
            return Promise.all(info_boolean_array).then(a => {
              if (a.every(i => i)) {
                return false;
              } else {
                return true;
              }
            })
          default:
            return false;
        }
      case 'type':

        type_array.map(item => {
          const key = item;
          const value = context.rootGetters['Project/getCurrentProjectSessionValueByKey'](key);
          type_boolean_array.push(context.dispatch('isValueSufficient', { key: key, value: value })); // not null, empty, or blank
        })
        switch (navigation_button) {
          case 'previous':
            return false;
          case 'next':
            return Promise.all(type_boolean_array).then(b => {
              if (b.every(i => i)) {
                return false;
              } else {
                return true;
              }
            })
          default:
            return false;
        }
      case 'package':
        package_array.map(item => {
          const key = item;
          const value = context.rootGetters['Project/getCurrentProjectSessionValueByKey'](key);
          package_boolean_array.push(context.dispatch('isValueSufficient', { key: key, value: value })); // not null, empty, or blank
        })
        switch (navigation_button) {
          case 'previous':
            return false;
          case 'next':
            return Promise.all(package_boolean_array).then(c => {
              if (c.every(i => i)) {
                return false;
              } else {
                return true;
              }
            })
          default:
            return false;
        }
      default:
        return false;
    }
  },
  isValueSufficient: async (context, payload) => {
    const key = payload.key;
    const value = payload.value;
    const project_session = context.rootState.Project.current_project_session;
    let isEmptyString = await context.dispatch('isEmptyString', { value: value });
    return new Promise((resolve) => {
      switch (key) {
        case 'project_id':
        case 'project_title':
        case 'project_description':
        case 'project_type':
          if (Object.prototype.hasOwnProperty.call(project_session, key) && !isEmptyString) {
            resolve(true)
          } else {
            resolve(false)
          }
          break;
        case 'project_type_other':
          if (project_session['project_type'] === 'other') {
            if (Object.prototype.hasOwnProperty.call(project_session, key) && !isEmptyString) {
              resolve(true)
            } else {
              resolve(false)
            }
          } else {
            resolve(true)
          }
          break;
        case 'project_package':
          if (Object.prototype.hasOwnProperty.call(project_session, key)) {
            resolve(true)
          } else {
            resolve(false)
          }
          break;
        default:
          resolve(false);
      }
    })
  },
  isEmptyString: (context, payload) => {
    const string = payload.value;
    let result = (string && string !== "" && string.length > 0) ? false : true;
    return result
  },
}
// Mutations
const mutations = {
  SET_STATE: (state, payload) => {
    const key = payload.key;
    const value = payload.value;
    state[key] = value
  },
  SET_ACTIVE_MENU_ITEM: (state, payload) => {
    state.activeMenuItem = payload
  },
  SET_ADMIN_BREADCRUMB: (state, payload) => {
    state.adminBreadcrumb = payload
  },
  SET_ORIENTATION: (state, payload) => {
    state.orientation = payload
  },
  SET_MEDIA_BREAKPOINT: (state, payload) => {
    state.device = payload
  },
  SET_ACTIVE_STEP: (state, payload) => {
    state.activeStep = payload
  },
  SET_IS_PREVIOUS_DISABLED: (state, payload) => {
    state.isPreviousDisabled = payload
  },
  SET_IS_NEXT_DISABLED: (state, payload) => {
    state.isNextDisabled = payload
  },
  SET_SEARCH: (state, payload) => {
    state.search = payload
  },
  SET_COMPONENT_KEY: (state, payload) => {
    state.componentKey = payload
  },
  SET_IS_APP_LOADING: (state, payload) => {
    state.isAppLoading = payload
  },
  SET_IS_LOADING_MODAL_ACTIVE: (state, payload) => {
    state.isLoadingModalActive = payload
  },
  SET_BULK_IMPORT_DATA: (state, payload) => {
    state.bulkImportData = payload
  },
  SET_BULK_IMPORT_ERRORS: (state, payload) => {
    state.bulkImportErrors = payload
  },
  SET_BULK_IMPORT_OPTIONS: (state, payload) => {
    state.bulkImportOptions = payload
  },
  RESET: state => {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
