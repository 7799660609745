/* users.store.js */
import {
  firestoreAction
} from 'vuexfire'
import {
  Functions
} from '@/firebase/functions'

// import the auto exporter
// import clean from './plugins/clean'

const initialState = () => ({
  project_users: []
})
// State object
const state = initialState()
// Getter functions
const getters = {
}
// Actions
const actions = {

  usersAction: firestoreAction(async(context, payload) => {
    let current_user_token = await context.dispatch('User/getCurrentUserToken', {}, {root:true})
    let project_id = context.rootState.Admin.project_id;
    payload.project_id = project_id;
    payload.current_user_token = current_user_token;
    payload.isBatched = payload.isBatched ? payload.isBatched : false;
    const data = payload;
    const usersAction = Functions.httpsCallable("usersAction")
    return new Promise((resolve, reject) => {
      return usersAction(data)
        .then((response) => {
            resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
    })
  }),
  getShirlyAuthUsers: firestoreAction(async(context) => { // MOVE THIS TO ADMIN
    let p = {}
    p.action = 'list_all_auth';
    let current_user_token = await context.dispatch('User/getCurrentUserToken', {}, {root:true})
    p.token = current_user_token;
    p.project_id = 'shirly';
    const usersAction = Functions.httpsCallable("usersAction")
    return new Promise((resolve) => {
      usersAction(p)
        .then((response) => {
          resolve(response.data)
        }).catch((error) => {
          console.log(error)
        });
    })
  }),
  setUserRole: firestoreAction((context, payload) => { // MOVE THIS TO ADMIN
    let data = payload.data

    const changeRole = Functions.httpsCallable("setUserRole")
    return new Promise((resolve, reject) => {
      changeRole(data)
        .then((response) => {
          if (response.data.message === 'Success') {
            resolve(response.data)
          } else {
            reject(response.data)
          }
        }).catch((error) => {
          console.log(error)
        });
    })
  }),
  rolesAction: firestoreAction(async(context, payload) => { // MOVE THIS TO ADMIN
    const rolesAction = Functions.httpsCallable("callRolesAction");
    payload.admin_secret = process.env.VUE_APP_FIREBASE_ADMIN_SECRET;
    return new Promise((resolve) => {
      rolesAction(payload)
        .then((response) => {
          console.log(response)
            resolve(response)
        }).catch((error) => {
          console.log(error)
        });
    })
  }),

}
// Mutations
const mutations = {
  RESET: state => {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
