<template>
  <b-dropdown position="is-bottom-left" append-to-body aria-role="menu" max-height="200" trap-focus>
    <template #trigger="{ active }">
      <b-button :label="dropdown.text" type="is-warning" :icon-right="active ? 'menu-up' : 'menu-down'" />
    </template>
    <b-dropdown-item v-for="item of profileItems" :key="item.name" @click="handle_function_call(item.fn_name)"
      aria-role="menuitem">{{ item.name }}</b-dropdown-item>
  </b-dropdown>
</template>

<script>
// import AppNavbarItem from '@/components/AppNavbarItem'
export default {
  name: 'NavigationProfileDropdown',
  props: {
    routerProps: {
      type: Object,
      default: null
    },
    dropdown: {
      type: Object,
      default: () => {
        return {
          text: '',
          path: '',
          type: '',
          image: {}
        }
      }
    }
  },
  components: {
    // AppNavbarItem
  },
  data() {
    return {
      profileItems: [
        {
          name: 'Account',
          fn_name: 'routeToAccount'
        },
        {
          name: 'Logout',
          fn_name: 'logout'
        }]
    }
  },
  watch: {

  },
  methods: {
    handle_function_call(fn_name) {
      this[fn_name]()
    },
    routeToAccount() {
      this.$router.push({ name: 'Account' })
    },
    logout() {
      this.$store.dispatch('User/logout')
    }
  }
}
</script>

<style scoped></style>
