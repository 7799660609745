import { required } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import store from '@/store'

async function getProjects(value, setLoadingMessage) {
  setLoadingMessage("Contacting server...");
  let payload = { action: 'projects_is_id_available', requested_id: value };
  const result = await store.dispatch('Functions/callFunction', { function_name: 'projectsOnCallProjectsSwitch', function_payload: payload });
  setLoadingMessage(null);
  return result;
}

extend("required", {
  ...required,
  message: "This field is required"
});

extend('id', async (value) => {
  console.log('called', value)
  if (value) {
    const decision = await getProjects(value, (message) => {
      console.log(message)
      store.dispatch('Project/setState', { key: 'project_id_loading_message', value: message });
    });
    if (decision) {
      return decision;
    } else {
      const error_message = 'Please select another project id.'
      store.dispatch('Project/setState', { key: 'project_id_loading_message', value: error_message });
      return error_message;
    }
  }
});

