/* Core */
import Vue from 'vue'
import '@/main.scss'
import Buefy from 'buefy'

/* Router & Store */
import { Router } from '@/router/index'
import store from './store'

/* Validation */
import "./services/vee-validate";

/* Firebase */
import { Auth } from './firebase/auth'

/* Service Worker */
import './registerServiceWorker'

/* Shirly */
import ShirlyPlugin from './plugins/shirly';

/* Vue. Main component */
import App from './App.vue'

/* Vuejs Bus */
export const bus = new Vue()

// Tell Vue.js to use custom Shirly pluginn
Vue.use(ShirlyPlugin)

Vue.prototype.$whiteLabelHostName = 'http://localhost:8081'

// Tell Vue.js to use Buefy
Vue.use(Buefy)

// Wrap the vue instance in a Firebase onAuthStateChanged method
// This stops the execution of the navigation guard 'beforeEach'
// method until the Firebase initialization ends

let app = '';

Auth.onAuthStateChanged(() =>{
  if(!app){
    app = new Vue({
      router: Router,
      store,
      render: h => h(App)
    }).$mount("#app");
  }
});
