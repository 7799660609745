// plugins/shirly.js

import {
  Analytics
} from '@/firebase/analytics'

export default {
  install: (Vue) => {

    Vue.prototype.$analytics = Analytics

    // inject a globally available $translate() method
    Vue.prototype.isEmptyObject = (obj) => {
      return Object.keys(obj).length === 0;
    }

    Vue.filter('capitalize', function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    })

    Vue.filter('capitalizeAll', (value, join) => {
      const sentence = value.replace(/_/g , ' ');
      const words = sentence.split(" ");
      const capitalize = (str, lower = false) => (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());

      for (let i = 0; i < words.length; i++) {
          words[i] = capitalize(words[i])
      }
      return words.join(join);
    });

    Vue.mixin({
      methods: {
        difference(a1, a2) {
          var a2Set = new Set(a2);
          return a1.filter(function(x) {
            return !a2Set.has(x);
          });
        },
        symmetricDifference(a1, a2) {
          return this.difference(a1, a2).concat(this.difference(a2, a1));
        },
        async getCustomDomainProjectId() {
          var self = this
          return new Promise((resolve, reject) => {
            async function getProjectIdByDomain(domain){

              return self.$store.dispatch('Settings/getProjectIdByDomain', { domain: domain }).then(r => {

                return r
              })
            }
            // const domain = 'http://localhost:8080'
            try {
              const domain = window.location.origin
              resolve(getProjectIdByDomain(domain))
            } catch(e){
              reject(e)
            }
          })
        },
      }
    })
  }
}
