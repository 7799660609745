<template>
  <b-navbar v-if="isDataLoaded" spaced>
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <app-logo :svg="{ height: null, width: '150px', path: 'shirly_logo_mgl_1.svg' }" />
      </b-navbar-item>

    </template>
    <template slot="end">
      <div v-for="item in navbarMenu" :key="item.id">
        <app-navbar-item v-if="item.type === 'button'" :text="item.text" :route="item.name" :url="item.url"
          :buttonClass="item.color" :icon="item.icon" />

        <navigation-profile-dropdown v-else-if="item.type === 'profile'"
          :dropdown="{ text: item.text, path: item.route, type: item.type, image: userProfileImage }" />
      </div>
    </template>
  </b-navbar>
</template>

<script>
import NavigationProfileDropdown from '@/components/NavigationProfileDropdown'
// import AppPreProductionTag from '@/components/AppPreProductionTag'
import AppNavbarItem from '@/components/AppNavbarItem'
import AppLogo from '@/components/AppLogo'
const navigation_init = require(`@/static/navigation_init.js`).navbar

export default {
  name: 'TheNavigation',
  components: {
    // AppPreProductionTag,
    AppNavbarItem,
    AppLogo,
    NavigationProfileDropdown
  },
  data() {
    return {
      navigation: navigation_init
    }
  },
  mounted() {

  },

  computed: {
    is_admin() {
      return this.$store.getters['User/getState']('is_admin');
    },
    currentUser() {
      return this.$store.getters['User/getState']('currentUser')
    },
    is_current_user() {
      return this.currentUser ? true : false;
    },
    userProfileImage() {
      const user = this.$store.getters['User/getUser']
      if (user) {
        if ('profile_image' in user) {
          return user.profile_image
        } else {
          return null
        }
      } else {
        return null
      }
    },
    isDataLoaded() {
      const nestedLoaded = Object.keys(this.navigation).map(key => this.navigation[key].length !== 0);
      return this.navigation && nestedLoaded.length !== 0
    },
    navbarMenu() {
      try {
        return this.navigation.filter(item => {
          if (item.id === 'login' && this.is_current_user) return false;
          if (item.id === 'account' && !this.is_current_user) return false;
          if (item.id === 'admin' && !this.is_admin) return false;
          if (item.id === 'demo') return false; // remove demo
          return true;
        });
      } catch {
        return this.navigation.filter(item => {
          if (item.id === 'login') return false;
          if (item.id === 'account') return false;
          if (item.id === 'admin') return false;
          if (item.id === 'demo') return false; // remove demo
          return true;
        });
      }
    },
  },
  methods: {

  }
}
</script>
