<template>
  <section id="app">
    <app-loading-modal />
    <span>
      <TheNavigation />
    </span>
    <router-view :key="$route.fullPath" />
  </section>
</template>

<script>
import { bus } from '@/main'
import TheNavigation from '@/components/TheNavigation.vue'
import AppLoadingModal from '@/components/AppLoadingModal.vue'
import appMixin from '@/mixins/appMixin'

export default {
  name: 'App',
  components: {
    TheNavigation,
    AppLoadingModal
  },
  mixins: [appMixin],
  data() {
    return {
      isFullPage: true,
      isLoading: false,
    };
  },
  created() {
    this.$store.dispatch('User/bindUser');
    this.$store.dispatch('Stripe/bindStripeRef')
    // when the app is created run the set user method
    // this uses Vuex to check if a user is signed in
    // check out mutations in the store.js file
    var self = this
    // Component B
    bus.$on('openLoading', function () {
      self.openLoading()
    })
    bus.$on('closeLoading', function () {
      self.closeLoading()
    })
  },
  mounted() {

  },
  computed: {
    user() {
      return this.$store.getters['User/getState']('user')
    },
    currentUser() {
      return this.$store.getters['User/getState']('currentUser')
    },
  },
  methods: {
    async getDidToken() {
      const token = await this.$store.dispatch('User/generateDidToken');
    },
    openLoading() {
      this.isLoading = true
    },
    closeLoading() {
      this.isLoading = false
    },
    openToast(toastObj) {
      this.$buefy.toast.open(toastObj)
    },
  }
}
</script>

<style scoped>
/* if you set up bulma-css-vars, this will be generated in your bulma scss */
</style>
