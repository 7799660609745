import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/router/routes/commonRoutes'
import { Magic } from 'magic-sdk';
const m = new Magic(process.env.VUE_APP_MAGIC_PUBLISHABLE_API_KEY);

Vue.use(VueRouter)
import { Auth } from '@/firebase/auth'
/* Shirly */
import store from '@/store'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  // linkExactActiveClass: "is-active",
  base: process.env.BASE_URL,
  routes: routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

Array.prototype.contains = function (obj) {
  var i = this.length;
  while (i--) {
    if (this[i] === obj) {
      return true;
    }
  }
  return false;
}

function getCookies() {
  const cookieArray = document.cookie.split('; ');
  const cookieObject = {};

  cookieArray.forEach(cookie => {
    const [key, value] = cookie.split('=');
    cookieObject[key] = value;
  });

  return cookieObject;
}

async function attemptLogin(did_token) {
  try {
    const decision = await store.dispatch('User/login', { supplied_did_token: did_token });
    if (decision !== 'failed') {
      return Auth.currentUser;
    }
  } catch (e) {
    console.error("Error during login:", e);
  }
  return null;
}

function forceRerender() {
  let componentKey = store.getters['Admin/getComponentKey']
  var incremental_component_key = componentKey + 1
  store.dispatch('Admin/setComponentKey', incremental_component_key)
}

export function later(delay) {
  return new Promise(function (resolve) {
    setTimeout(resolve, delay);
  });
}

router.beforeEach(async (to, from, next) => {
  let isUserAuthenticated = false;
  store.dispatch('Admin/setState', { key: 'is_app_loading', value: true });
  let currentUser = Auth.currentUser;
  isUserAuthenticated = currentUser ? true : false;
  if (!currentUser) {
    try {
      if (!isUserAuthenticated) {
        if (window.location.search) {
          const newDidToken = await m.auth.loginWithCredential(window.location.search);
          if (newDidToken) {
            currentUser = await attemptLogin(newDidToken);
          }
        }
      }
      isUserAuthenticated = true;
    } catch (e) {
      console.error("An error occurred:", e);
      isUserAuthenticated = false;
      // Handle specific errors based on their type if needed
    }
  }
  await later(300)
  // const type = domain_type.type
  const path = to.fullPath
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiresPermission = to.matched.some(record => record.meta.requiresPermission)
  // const requiresPermission = to.matched.some(record => record.meta.requiresPermission)

  if (requiresAuth && !currentUser) { // requires user authorization and user is not authorized ==> Login
    // store.dispatch('Admin/setRouterViewIsReady', true)
    next({
      name: 'Login',
      params: {
        desired_route: path
      }
    })
  } else if (requiresAuth && currentUser) { // requires user authorized and user is authorized ==> next()
    // console.log(to)
    Auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        setTimeout(async () => {
          store.dispatch('Admin/bindAppRef').then((r) => {
            if (r.type === 'Success') {
              store.dispatch('Admin/setState', { key: 'is_app_bound', value: true });
              store.dispatch('Admin/setState', { key: 'is_app_loading', value: false });
            } else {
              console.error(r)
              store.dispatch('Admin/setState', { key: 'is_app_bound', value: false });
              store.dispatch('Admin/setState', { key: 'is_app_loading', value: false });
            }
            forceRerender()
          })
        }, "300")
        Auth.currentUser.getIdTokenResult().then(async (result) => {
          let project_id = 'shirly';
          let roles = result.claims
          var roles_keys = Object.keys(roles)
          let admin_roles = ['admin', 'owner']
          let project_role = roles[project_id] ? roles[project_id] : null;
          const hasProjectRole = roles_keys.includes(project_id);
          const hasAdminPermission = admin_roles.contains(project_role);
          store.dispatch('User/setState', { key: 'is_admin', value: hasAdminPermission });
          if (requiresPermission) {
            if (!hasProjectRole || !hasAdminPermission) {
              return router.replace({
                name: 'Home'
              })
            } else {
              // store.dispatch('Admin/setRouterViewIsReady', true)
              next()
            }
          }
          // store.dispatch('Admin/setRouterViewIsReady', true)
          next()
        })
      }
    })
  } else { // does not requireAuth and not currentUser (ie. login)
    // store.dispatch('Admin/setRouterViewIsReady', true)
    store.dispatch('Admin/setState', { key: 'is_app_loading', value: false });
    next()
  }
})

export const Router = router
