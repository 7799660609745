import Vue from 'vue'
import { vuexfireMutations } from 'vuexfire'
import Vuex from 'vuex'
// import createLogger from 'vuex/dist/logger'

// import the auto exporter
import modules from './modules'

Vue.use(Vuex)
// Making sure that we're doing
// everything correctly by enabling
// strict mode in the dev environment.
// const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules,
  actions: {
    reset ({ commit }) {
      // resets state of all the modules
      Object.keys(modules).forEach(moduleName => {
        commit(`${moduleName}/RESET`)
      })
    }
  },
  mutations: {
    ...vuexfireMutations
  }
  // strict: debug,
  // plugins: [cleanPlugin] // debug ? [createLogger(), cleanPlugin] : [cleanPlugin]
})
