/* cloud[inary].store.js */

// State initialization
const state = {
  widget: null,
  signature: null,
  timestamp: null,
  callback: null,
  preset: null,
  upload_button_visible: true
};

// Define getters for state properties
const getters = {
  getState: state => key => state[key],
};

// Define actions
const actions = {
  // Commit payload to state
  setState({ commit }, payload) {
    commit('SET_STATE', payload);
    return Promise.resolve('Okay');
  },

  // Initialize upload widget
  async initUploadWidget({ commit, dispatch, getters }, { type, queue_path, ref_doc_path, bindAction, callback = true, additional_params = {} }) {
    try {
      console.log(additional_params); // It will log an empty object if additional_params is not provided
      const preset = await dispatch('getPreset');
      await dispatch('getSignature', preset);

      // Params for cloudinary widget
      const params = {
        apiKey: process.env.VUE_APP_CLOUDINARY_API_KEY,
        cloudName: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME,
        uploadPreset: getters.getState('preset'),
        uploadSignatureTimestamp: getters.getState('timestamp'),
        uploadSignature: getters.getState('signature'),
      };

      console.log(params)

      // Wrap Cloudinary widget creation in a Promise
      return new Promise((resolve, reject) => {
        const widget = window.cloudinary.createUploadWidget(params, (error, result) => {
          console.log(result)
          if (!error && result && result.event === 'success') {
            if (callback) {
              resolve(dispatch('defaultCallback', { image: result.info, queue_path, ref_doc_path, bindAction, additional_params }));
            } else {
              resolve(result.info); // Resolve the Promise with the result.info
            }
          } else if (error) {
            console.error('Error uploading: ', error);
            reject(error); // Reject the Promise if there's an error
          } else if (result && result.event === 'close') {
            // Handle the 'close' event
            console.log('Upload widget was closed without any action.');
            resolve(null); // Resolve the Promise with null to signify no action
          }
        });

        commit('SET_STATE', { key: 'widget', value: widget });
        dispatch('openUploadWidget');
      });
    } catch (error) {
      console.error('An error occurred during widget initialization:', error);
      throw error; // Propagate the error up the call chain
    }
  },


  // Get signature
  async getSignature({ commit, dispatch }, preset) {
    try {
      const response = await dispatch('Functions/callFunction', { function_name: 'projectMediaOnCallCloudinarySwitch', function_payload: { action: 'generate_signature', params: { upload_preset: preset } } }, { root: true });
      commit('SET_STATE', { key: 'signature', value: response.signature });
      commit('SET_STATE', { key: 'timestamp', value: response.timestamp });
      return response;
    } catch (error) {
      console.error('Error getting signature: ', error);
      throw error;
    }
  },

  // Get preset
  async getPreset({ commit }) {
    try {
      const preset = 'shirly_tmp_project_images';
      commit('SET_STATE', { key: 'preset', value: preset });
      return preset;
    } catch (error) {
      console.error('Error getting preset: ', error);
      throw error;
    }
  },

  async bindAction({ dispatch }, params) {
    const { action_path, action_payload } = params;
    try {
      await dispatch(action_path, action_payload, { root: true });
    } catch (error) {
      console.error('Error with bind action event: ', error);
      throw error;
    }
  },

  // Default callback
  async defaultCallback({ dispatch }, { image, queue_path, ref_doc_path, bindAction, additional_params }) {
    const res = await dispatch('Functions/callFunction', {
      function_name: 'firekitOnCallFirestoreSwitch',
      function_payload: {
        action: "add", path: queue_path, payload: {
          state: "NEW",
          ref_doc_path: `${ref_doc_path}/${image.public_id.split("/").pop()}`,
          action: 'firestore_switch',
          subaction: 'set',
          payload: { ...image, ...additional_params }
        }
      }
    }, { root: true });
    if (bindAction) {
      return setTimeout(() => {
        dispatch('bindAction', bindAction);
      }, 1000)
    } else {
      return res.data.payload
    }
  }
  ,

  // Open upload widget
  openUploadWidget({ state }) {
    state.widget.open();
  },
};

// Define mutations
const mutations = {
  // Set a state property
  SET_STATE(state, { key, value }) { state[key] = value; },
  // Reset state to initial state
  RESET(state) { Object.assign(state, { widget: null, signature: null, timestamp: null, preset: null, }); },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
