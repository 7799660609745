/* user.store.js */
import { firestoreAction } from 'vuexfire';
import { DB } from '@/firebase/db';
import { Auth } from '@/firebase/auth';
import { Functions } from '@/firebase/functions';
import { Magic } from 'magic-sdk';
import { ToastProgrammatic as Toast } from 'buefy';
import { Router, later } from '@/router/index';
import firebase from 'firebase/compat/app'
import axios from 'axios'

// Initialize Magic SDK
const m = new Magic(process.env.VUE_APP_MAGIC_PUBLISHABLE_API_KEY);

// Initial State
const initialState = () => ({
  currentUser: null,
  user: {},
  projects: [],
  user_channels: [],
  bulk_import_sessions: [],
  user_role: null,
  user_group: null,
  is_admin: false
})

// State object
const state = initialState()

// Getter functions
const getters = {
  getState: (state) => (key) => {
    return state[key]
  },
  getCurrentUser: state => state.currentUser,
  getUser: state => state.user,
  getUserRole: state => state.user_role,
}
// Actions
const actions = {
  reset({
    commit
  }) {
    commit('RESET')
  },
  setState: (context, payload) => {
    return new Promise((resolve) => {
      context.commit('SET_STATE', payload);
      resolve('Okay');
    })
  },
  bindUser: async (context, payload) => {
    const { exceptions = [] } = payload || {};  // Default to an empty array if exceptions is undefined
    context.dispatch('setCurrentUser');
    const currentUser = context.getters['getState']('currentUser');
    if (currentUser) {
      if (!exceptions.includes('bindUserRef')) {
        await context.dispatch('bindUserRef');
      }
      if (!exceptions.includes('bindProjectsRef')) {
        await context.dispatch('bindProjectsRef');
      }
      if (!exceptions.includes('bindUserRole')) {
        await context.dispatch('bindUserRole');
      }
      if (!exceptions.includes('Admin/bindAppRef')) {
        await context.dispatch('Admin/bindAppRef', {}, { root: true });
      }
      if (!exceptions.includes('Admin/setState')) {
        await context.dispatch('Admin/setState', { key: 'is_app_loading', value: false }, { root: true });
      }
    }
  },

  setCurrentUser: context => {
    context.commit('SET_CURRENT_USER')
  },
  setUserRole: (context, payload) => {
    context.commit('SET_USER_ROLE', payload)
  },
  setUserGroup: (context, payload) => {
    context.commit('SET_USER_GROUP', payload)
  },
  getProjectRole: async (context) => {
    const user = await DB.collection('users').doc(Auth.currentUser.uid).get()
    const roles = user.data().roles
    let project_id = context.rootState.Admin.project_id
    const role = roles[project_id]
    return role
  },

  bindUserRef: firestoreAction((context) => {
    return context.bindFirestoreRef('user', DB.collection('users').doc(context.state.currentUser.uid))
  }),
  bindUserRole: (async (context) => {
    let user_data = await Auth.currentUser.getIdTokenResult()
    let project_id = 'shirly'
    let roles = user_data.claims;
    if (roles[project_id] === 'admin') {
      return context.commit('SET_STATE', {
        key: 'is_admin',
        value: true
      });
    }
    context.commit('SET_USER_ROLE', roles[project_id]);
  }),
  bindUserGroup: (async (context) => {
    const project_group = context.rootGetters['Groups/getGroupField'];
    if (project_group) {
      const project_group_id = project_group.id
      let user = state.user
      let group = user[project_group_id]
      context.commit('SET_USER_GROUP', group)
    }
  }),
  bindUserChannelsRef: firestoreAction(context => {
    let project_id = context.rootState.Admin.project_id
    context.bindFirestoreRef('user_channels', DB.collection('users').doc(Auth.currentUser.uid).collection('channels').doc('projects').collection(project_id))
  }),
  bindBulkImportSessions: firestoreAction(async (context) => {
    return await new Promise((resolve, reject) => {
      context.bindFirestoreRef('bulk_import_sessions', DB.collection('users').doc(Auth.currentUser.uid).collection('bulk_import_sessions').orderBy("createdAt", "asc"))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err)
        })
    })
  }),

  bindProjectsRef: firestoreAction(async (context) => {
    const user = await DB.collection('users').doc(Auth.currentUser.uid).get();
    const roles = user.data().roles;
    const roles_keys = Object.keys(roles);

    // Use Promise.all to wait for all promises to resolve
    const projects = await Promise.all(roles_keys.map(role =>
      context.dispatch('Functions/callFunction', {
        function_name: 'firekitOnCallFirestoreSwitch',
        function_payload: { action: 'get', path: `projects/${role}` }
      }, { root: true }).then(result => result?.data)
    ));

    // Assuming that projects are an array, iterate through them directly
    var user_projects = [];
    projects.forEach((doc, index) => {
      if (doc) { // Check if doc exists
        var found = roles_keys[index];
        user_projects.push({
          id: doc.id,
          data: doc,
          roles: roles[found]
        });
      }
    });

    let sorted_projects = user_projects.sort((a, b) => (a.data.createdAt > b.data.createdAt) ? 1 : -1);
    console.log('sorted_projects', sorted_projects)
    context.commit('SET_STATE', {
      key: 'projects',
      value: sorted_projects
    });
  }),
  getCurrentUserToken: firestoreAction(() => { // MOVE THIS TO ADMIN
    const getCurrentUserTokenFunction = () => {
      return Auth.currentUser.getIdToken(true)
        .then(function (token) {
          // You got the user token
          return token
        })
        .catch(function (err) {
          return (err);
        });
    }
    return new Promise((resolve, reject) => {
      getCurrentUserTokenFunction()
        .then((response) => {
          resolve(response);
        }, error => {
          // http failed, let the calling function know that action did not work out
          reject(error);
        })
    })
  }),
  doesUserEmailExist: (context, payload) => {
    const doesUserEmailExist = Functions.httpsCallable("doesUserEmailExist")
    return new Promise((resolve, reject) => {
      return doesUserEmailExist(payload)
        .then((response) => {
          resolve(response);
        }, error => {
          // http failed, let the calling function know that action did not work out
          reject(error);
        })
    })
  },

  setCookie: (context, payload) => {
    const { name, value, domain } = payload;
    const expires = new Date();
    expires.setTime(expires.getTime() + (24 * 60 * 60 * 7 * 1000)); // 7 days
    document.cookie = `${name}=${value};domain=${domain};path=/;`;
    // document.cookie = `${name}=${value};expires=${expires.toUTCString()};domain=${domain};path=/;`;
  },

  // createSessionCookie: async (context, payload) => {
  //   const { client_token } = payload;
  //   try {
  //     const app_id = process.env.VUE_APP_FIREBASE_PROJECT_ID;
  //     const idToken = client_token;
  //     const response = await axios.post(`https://us-central1-shirly-2413a.cloudfunctions.net/authenticateOnRequestCreateSessionCookie`, { idToken }, { withCredentials: true });
  //     console.log('response', response)
  //     return response;
  //   } catch (e) {
  //     console.error(e)
  //   }
  // },

  deleteCookie: (context, payload) => {
    console.log('deleteCookie called')
    const { name, domain } = payload;
    const expiredDate = new Date(0).toUTCString(); // Setting the date to epoch time (January 1, 1970), effectively expiring it
    document.cookie = `${name}=; expires=${expiredDate}; domain=${domain};path=/;`;
  },

  login: async (context, payload) => {

    function setModalLoadingState(isLoading) {
      context.dispatch('Modal/setState', { key: 'is_modal_active', value: isLoading }, { root: true });
      if (isLoading) {
        context.dispatch('Modal/setState', { key: 'modal', value: 'loading' }, { root: true });
      }
    }

    function handleFailedLogin(result) {
      setModalLoadingState(false);
      Toast.open({
        duration: 10000,
        message: `${result.message}`,
        position: 'is-top',
        type: 'is-warning'
      });
    }

    try {
      const { email, phone, supplied_did_token } = payload || {};
      const origin = window.location.origin;
      let didToken = supplied_did_token;
      if (!didToken) {
        if (email) {
          didToken = await m.auth.loginWithEmailOTP({
            email,
          });
          console.log('didToken', didToken)
        } else if (phone) {
          didToken = await m.auth.loginWithSMS({
            phoneNumber: phone,
          });
          console.log('didToken', didToken)
        }
      } else {
        console.log('didToken', didToken)

      }

      const functionPayload = {
        action: 'login',
        payload: {
          domain: origin,
          magicToken: didToken,
          redirect: origin
        }
      };
      context.dispatch('Modal/setState', { key: 'modal', value: 'signin' }, { root: true })
      context.dispatch('Modal/setState', { key: 'is_modal_active', value: true }, { root: true })

      const result = await context.dispatch('Functions/callFunction', {
        function_name: 'authenticateOnCallAuthenticate',
        function_payload: functionPayload
      }, { root: true });
      console.log(result)
      const decision = result?.type || 'Failed';
      if (decision === 'Failed') {
        if (result?.message) {
          const { message } = result;
          const isInvalidToken = message.includes("Magic token is invalid") || message.includes("The DID token is malformed");
          if (!isInvalidToken) {
            handleFailedLogin(result);
          }
        }
      } else {
        try {
          if (result.data && result.data.uid) {
            if (!result?.data.token) throw new Error('Could not get token from data');
            await Auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
            await Auth.signInWithCustomToken(result.data.token);
            const user = await DB.collection('users').doc(result.data.uid).get();
            if (user.exists) {
              context.dispatch('bindUser');
              setModalLoadingState(false);
              await Router.push({
                name: 'Account'
              }).catch(err => console.error("Navigation error:", err));
            }
          } else {
            console.error("UID is missing");
          }
        } catch (error) {
          console.error("Error accessing user document:", error);
          // Handle the error appropriately
        }
      }
      context.dispatch('Modal/setState', { key: 'modal', value: 'loading' }, { root: true })
      context.dispatch('Modal/setState', { key: 'is_modal_active', value: false }, { root: true })
      return decision !== 'Failed' ? 'Success' : 'Failed';

    } catch (e) {
      console.error(e); // For better error debugging
      return 'Failed';
    }
  },

  logout: async (context) => {
    const router_view_wait = 500;
    context.dispatch('Admin/setIsAppLoading', true, {
      root: true
    })
    await m.user.logout().then(() => {
      Auth
        .signOut()
        .then(async () => {
          context.commit('CLEAR_USER_DATA');
          context.dispatch('reset', { omissions: ['Settings'] }, {
            root: true
          });
          await later(router_view_wait);
          let currentPath = Router.currentRoute.path;
          console.log('route')
          if (currentPath !== '/login') {
            await Router.push({
              name: 'Login'
            });
            await later(300);
            context.dispatch('Admin/setIsAppLoading', false, {
              root: true
            });
          } else {
            context.dispatch('Admin/setIsAppLoading', false, {
              root: true
            });
            await Router.go(0);
          }
        })
        .catch((error) => {
          console.error(error)
        });
    });
  },
  signup: async (context, payload) => {
    function handleFailedSignup(result) {
      Toast.open({
        duration: 10000,
        message: `${result.message}`,
        position: 'is-top',
        type: 'is-warning'
      });
    }
    context.dispatch('Admin/setIsAppLoading', true, {
      root: true
    })
    const functionPayload = {
      action: 'signup',
      payload
    };

    console.log(functionPayload)
    try {
      const result = await context.dispatch('Functions/callFunction', {
        function_name: 'authenticateOnCallAuthenticate',
        function_payload: functionPayload
      }, { root: true });
      console.log(result)
      const decision = result?.type || 'Failed';
      if (decision === 'Failed') {
        handleFailedSignup(result);
      } else {
        const { data } = result;
        const { token } = data;
        await Auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        await Auth.signInWithCustomToken(token);
        if (result.data && result.data.uid) {
          const user = await DB.collection('users').doc(result.data.uid).get();
          if (user.exists) {
            context.dispatch('bindUser', { exceptions: ['bindUserRole'] });
            context.dispatch('Admin/setIsAppLoading', false, {
              root: true
            })
            await Router.push({
              name: 'Account'
            }).catch(err => console.error("Navigation error:", err));
          }
        } else {
          console.error("UID is missing");
        }
      }
    } catch (e) {
      console.error(e)
    }
  },
}
// Mutations
const mutations = {
  SET_STATE: (state, payload) => {
    const key = payload.key;
    const value = payload.value;
    state[key] = value
  },
  SET_CURRENT_USER: state => {
    state.currentUser = Auth.currentUser
  },
  SET_USER_ROLE: (state, payload) => {
    state.user_role = payload
  },
  SET_USER_GROUP: (state, payload) => {
    state.user_group = payload
  },
  CLEAR_USER_DATA: state => {
    state.currentUser = null
    state.user = {}
  },
  RESET: state => {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
