/* stripe.store.js */
import Vue from 'vue'
import deepMerge from 'deepmerge'
import { vuexfireMutations, firestoreAction, bindFirestoreRef } from "vuexfire";
import { DB } from '@/firebase/db'
import { Auth } from '@/firebase/auth'
import { Functions } from '@/firebase/functions'
import { loadStripe } from '@stripe/stripe-js';
import { sort } from 'fast-sort'


const initialState = () => ({
  products: [],
  session_id: null,
  stripe_checkout_session: null,
  loadingStatus: {},
})
// State object
const state = initialState()
// Getter functions
const getters = {
  getState: (state) => (key) => {
    return state[key]
  },
  getStatePromise: (state) => (key) => {
    return new Promise((resolve) => {
      resolve(state[key])
    })
  },
  getProducts: state => state.products,
  getSessionId: state => state.session_id,
}
// Actions
const actions = {
  reset({
    commit
  }) {
    commit('RESET')
  },
  setState: (context, payload) => {
    return new Promise((resolve) => {
      context.commit('SET_STATE', payload);
      resolve('Okay');
    })
  },
  setLoadingStatus: ({ commit }, payload) => {
    return new Promise((resolve) => {
      commit('SET_LOADING', payload);
      resolve('Loading status set');
    });
  },
  bindStripeRef: firestoreAction(async ({ dispatch, state, bindFirestoreRef }) => {
    await bindFirestoreRef("products", DB.collection("products").where('active', '==', true));
    const products = state.products;
    const sorted_products = sort(products).by([
      { asc: u => u.position }
    ]);

    await dispatch('setState', { key: 'products', value: sorted_products })
    await dispatch('populateProductsWithFeatures', {})
    await dispatch('populateProductsWithPrices', {})
  }),
  async populateProductsWithFeatures({ state }) {
    await Promise.all(
      state.products.map(async (product) => {
        const featuresCollection = DB.collection(`products/${product.id}/features`).orderBy('position', 'asc');
        const featuresSnapshot = await featuresCollection.get();
        Vue.set(product, "features", featuresSnapshot.docs.map(doc => doc.data()));
      })
    );
  },
  async populateProductsWithPrices({ state }) {
    await Promise.all(
      state.products.map(async (product) => {
        const pricesCollection = DB.collection(`products/${product.id}/prices`);
        const pricesSnapshot = await pricesCollection.get();
        const pricesWithIds = pricesSnapshot.docs.map(doc => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
        Vue.set(product, "prices", pricesWithIds);
      })
    );
  },
  createCheckoutSession: firestoreAction(async (context, payload) => {
    try {
      const { project_session_id, project_type } = payload;
      const uid = context.rootState.User.currentUser.uid;
      const session_data = {
        // required
        success_url: `${window.location.origin}/new-project/success?session_id={CHECKOUT_SESSION_ID}`,
        cancel_url: `${window.location.origin}/new-project/failed`,
        line_items: [{ price: payload.price, quantity: 1 }],
        mode: 'subscription',
        // optional
        metadata: {
          project_session_id,
          uid,
          project_type
        }
      }

      await context.dispatch('Functions/callFunction', {
        function_name: 'firekitOnCallFirestoreSwitch', function_payload: {
          action: 'add',
          path: `users/${uid}/checkout_sessions`,
          payload: session_data
        }
      }, { root: true });
      const snapshot = await DB.collection('users').doc(uid).collection('checkout_sessions')
        .orderBy('createdAt', 'desc')
        .limit(1)
        .get();
      if (snapshot.empty) {
        console.log('No matching document found.');
        return;
      }
      let lastCheckoutSessionDocId;
      snapshot.forEach(doc => {
        lastCheckoutSessionDocId = doc.id
      });
      return context.bindFirestoreRef('stripe_checkout_session', DB.collection('users').doc(uid).collection('checkout_sessions').doc(lastCheckoutSessionDocId));
    } catch (e) {
      console.error(e)
    }
  }),
  subscribe: async (context) => {
    const stripe_publishable_key = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    try {
      const stripe = await loadStripe(stripe_publishable_key);
      const stripe_checkout_session = context.state.stripe_checkout_session;
      const sessionId = stripe_checkout_session.session.id;
      return stripe.redirectToCheckout({
        sessionId
      })
    } catch (e) {
      console.error(e)
    }
  },
  getProductPrices: firestoreAction(async (context, payload) => {
    const snapshot = await DB.collection('products').doc(payload).collection('prices').get()
    return snapshot.docs.map(doc => {
      return {
        id: doc.id,
        data: doc.data()
      }
    });
  }),
  getDatabaseId: firestoreAction(async (context, payload) => {
    return DB.collection(payload.collection).doc(payload.id).id
  }),
  createNewStripeSubscribedProject: firestoreAction((_, payload) => { // MOVE THIS TO ADMIN
    const data = {
      session_id: payload,
    }
    const createNewStripeSubscribedProject = Functions.httpsCallable("createNewStripeSubscribedProject")
    return Auth.currentUser.getIdToken(true).then(function (token) {
      data.currentUserToken = token
      return new Promise((resolve, reject) => {
        createNewStripeSubscribedProject(data)
          .then((response) => {
            resolve(response);
          }, error => {
            // http failed, let the calling function know that action did not work out
            reject(error);
          })
      })
    }).catch(function (err) {
      console.error(err);
    });
  }),
}
// Mutations
const mutations = {
  ...vuexfireMutations,
  SET_STATE: (state, payload) => {
    const key = payload.key;
    const value = payload.value;
    state[key] = value
  },
  RESET: state => {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_SESSION_ID: (state, payload) => {
    state.session_id = payload
  },
  SET_LOADING(state, payload) {
    const { itemId, status } = payload;
    Vue.set(state.loadingStatus, itemId, status);
  },
  ADD_PRODUCT_PRICES: (state, payload) => {
    const prices = {
      prices: payload.data
    }
    const index = state.products.findIndex((item) => item.id === payload.id);
    Vue.set(state.products, index, deepMerge(state.products[index], prices));
  },
  MERGE_PRODUCT_DATA: (state, payload) => {
    var key = payload.key
    var val = payload.val
    var find_key = payload.find_key
    var find_val = payload.find_val
    var data = {}
    data[key] = val
    const index = state.products.findIndex((item) => item[find_key] === find_val);
    Vue.set(state.products, index, deepMerge(state.products[index], data));
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
